import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";

// graphics
import link from './icons/link.svg';
import revomed from './icons/revomed.svg';

import revomedCase1 from './img/revomedCase1.png';
import revomedCase2 from './img/revomedCase2.png';
import revomedCase3 from './img/revomedCase3.png';

// styles
import "./Case.scss";

let CaseRevomed = () => {
	return (
		<div className="Case">
			<Header />
			<Helmet>
				<title>Кейс Revomed — Amont.studio</title>
				<meta name="description" content="Рассказываем о разработке сервиса онлайн телемедицины с проверенными врачами" />
			</Helmet>
			<div className="Case__main-section pt-8 pb-8">
				<div className="container-fluid mb-8">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-11 offset-xxl-1 offset-xxxl-2 mb-8 d-flex flex-wrap">
							<span className="d-block mb-4">Кейсы / Revomed</span>
							<h1 className="mb-4">Сервис онлайн телемедицины с проверенными врачами</h1>
							<a href="https://revomed.ru" className="d-flex align-items-center justify-content-center"><img src={link} alt="" className="mr-2"/>Перейти на сайт</a>
						</div>
						<div className="col-4 col-xl-3 offset-3 offset-xl-6 mb-8 d-none d-lg-block">
							<div className="Case__logo revomed d-flex justify-content-center align-items-center">
								<img src={revomed} alt="" />
							</div>
						</div>
						<div className="col-24 col-lg-15 col-xl-13 col-xxl-11 col-xxxl-10 offset-xxl-1 offset-xxxl-2">
							<span className="d-block mb-4">Клиент и задача</span>
							<p className="mb-4">Revomed — амбициозная платформа видеоконсультаций с врачами с простым и удобным интерфейсом. Партнёрами являются сертифицированные частные врачи или мед. учреждения с соответствующей лицензией, на осуществление медицинской деятельности.</p>
							<p>Наша задача — разработать с нуля платформу видеоконсультация с собственной видеосвязью на основе прототипов и дизайна.</p>
						</div>
					</div>
				</div>
				<div className="container-fluid p-0 mb-7 pb-5">
					<img src={revomedCase1} className="w-100"/>
				</div>
				<div className="container-fluid mb-7">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2 mb-8">
							<h2 className="mb-4">Overview & технологии</h2>
							<p className="mb-4">Платформа включает в себя более 30 основных страниц, с которыми взаимодействуют пациенты и врачи. Также третьим типом пользователей являются клиники, которые могут подключать к сервису своих сотрудников.</p>
							<p>Платформа состоит из 2 отдельных React-приложений: для пользователей и для администраторов. UI-компонентов более 200. Приложения общаются с бекендом по API, которое мы разработали на NodeJS.</p>
						</div>
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2 mb-5">
							<h2 className="mb-4">Видеосвязь на WebRTC</h2>
							<p>Для целостности предоставляемой услуги платформой и удобства пользователей была создана собственная реализация протокола WebRTC, подняты собственные сервера TURN и STAN для прохождения NAT перед подключением. Способ видеосвязи был выбран не случайно — с помощью этого протокола данные передаются напрямую между браузерами врача и пациента, что исключает участие сервера и позволяет пользователям быть уверенными, что их консультации остаются конфиденциальными.</p>
						</div>
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 mb-5">
							<img src={revomedCase2} className="w-100"/>
						</div>
					</div>
				</div>
				<div className="container-fluid mb-7">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2 mb-5">
							<h2 className="mb-4">Блоггинг</h2>
							<p>Созданию блога на платформе было уделено много времени, ведь статьи могут выпускаться от врачей/частных консультантов, клиник или администрации. В административной панели визуальный текстовый редактор</p>
						</div>
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 mb-5">
							<img src={revomedCase3} className="w-100"/>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 d-flex flex-wrap">
							<h2 className="w-100 mb-4">Компетенции</h2>
							<span className="main-section__span mr-3 mb-3">Front-end</span>
							<span className="main-section__span mr-3 mb-3">back-end</span>
							<span className="main-section__span mr-3 mb-3">веб-дизайн</span>
							<span className="main-section__span mr-3 mb-3">проектирование</span>
							<span className="main-section__span mr-3 mb-3">react</span>
							<span className="main-section__span mr-3 mb-3">nodejs</span>
							<span className="main-section__span mr-3 mb-3">webrtc</span>
							<span className="main-section__span mr-3 mb-3">ui</span>
							<span className="main-section__span mr-3 mb-3">ux</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CaseRevomed;