import React from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

// slider init
import 'swiper/swiper.scss';
// styles
import "./Slider.scss";

export default function SimpleSlider(props) {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
  };
  return (
    <>
    <Swiper className="ReviewsSlider__carousel" slidesPerView={1} spaceBetween={30} slidesPerColumnFill="row" slidesPerColumn={1} breakpoints={{1600: {slidesPerView: 3.05}, 1400: {slidesPerView: 2.9}, 1200: {slidesPerView: 2.5}, 992: {slidesPerView: 2.5}, 768: {slidesPerView: 2, spaceBetween: 42}, 576: {slidesPerView: 1.7}}} >
        {props.info.map((item, key) =>
        <SwiperSlide key={key}>
          <Link to={"/post/" + item.id} className="slide d-flex align-content-between flex-wrap p-4">
            <div className="d-flex justify-content-end w-100 mb-5">
              <span className="category">{item.category}</span>
            </div>
            <div>
              <h3>{item.title}</h3>
              <span className="date">{DateTime.fromISO(item.date).setLocale('ru').toFormat('dd MMMM yyyy')}</span>
            </div>
          </Link>
        </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}