import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import API from "../utils/API";
import { fadeInRight, fadeInLeft, fadeInDown, fadeIn } from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import ScrollAnimation from 'react-animate-on-scroll';
import {Helmet} from "react-helmet";

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";
import Welcome from "../blocks/Welcome/Welcome";
import Belive from "../blocks/Belive/Belive";
import News from "../blocks/News/News";
import Gradient from "../atoms/Gradient/Gradient";


// graphics
import revomed from './img/revomed.png';
import jenis from './img/jenis.png';
import easy2book from './img/easy2book.png';
import yourProject from './img/yourProject.png';


// styles
import "./Home.scss";

let Home = () => {
	let [posts, setPosts] = useState(null)
	
	useEffect(() => {
		// GET BLOG
		let getBlog = async (data) => {
			let res = await API.get("/posts", data);
			return await res.data;
		}
		getBlog().then((result) => {
			setPosts(result.posts);
		})
	}, [])



	let [isScrolling, setIsScrolling] = useState(false);
	let [visibleElementAboutSection, setVisibleElementAboutSection] = useState(false)
	let [visibleElementMainSection, setVisibleElementMainSection] = useState(false)
	let [visibleElementBelive, setVisibleElementBelive] = useState(false)

	useEffect(() => {
		window.addEventListener("scroll", throttleScroll, false);
		function throttleScroll(e) {
			if (isScrolling === false) {
				window.requestAnimationFrame(function() {
					scrolling(e);
					setIsScrolling(false);
				});
			}
			setIsScrolling(true);
		}
		document.addEventListener("DOMContentLoaded", scrolling, false);
		function scrolling(e) {
			let AboutSection = document.querySelector(".Home__about-section");
			let MainSection = document.querySelector(".Home__main-section");
			let Belive = document.querySelector(".Belive");
			(MainSection) ? ((isPartiallyVisible(MainSection)) && (visibleElementMainSection === false)) ? setVisibleElementMainSection(true) : setVisibleElementMainSection(false) :  setVisibleElementMainSection(false);
			AboutSection ? ((isPartiallyVisible(AboutSection)) && (visibleElementAboutSection === false)) ? setVisibleElementAboutSection(true) : setVisibleElementAboutSection(false) : setVisibleElementMainSection(false);
			Belive ? ((isPartiallyVisible(Belive)) && (visibleElementBelive === false)) ? setVisibleElementBelive(true) : setVisibleElementBelive(false) : setVisibleElementMainSection(false);
		}
		function isPartiallyVisible(el) {
			let elementBoundary = el.getBoundingClientRect();

			return ((elementBoundary.top + elementBoundary.height >= 0) && (elementBoundary.height + window.innerHeight >= elementBoundary.bottom));
		}
		function isFullyVisible(el) {
			let elementBoundary = el.getBoundingClientRect();
			console.log("elementBoundary MainSection", elementBoundary)
			return ((elementBoundary.top >= 0) && (elementBoundary.bottom <= window.innerHeight));
		}
	}, [])


	const styles = {
		fadeInRight: {
			animation: 'x 2s',
			animationName: Radium.keyframes(fadeInRight, 'fadeInRight'),
		},
		fadeInLeft: {
			animation: 'x 2s',
			animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft'),
		},
		fadeInDown: {
			animation: 'x 2s',
			animationName: Radium.keyframes(fadeInDown, 'fadeInDown'),
		}
	}

	return (
		<>
		<Helmet>
			<title>Создаем потрясающие цифровые продукты — AMONT.STUDIO</title>
			<meta name="description" content="Помогаем бизнесу с IT-проектами благодаря огромному опыту и сильной экспертизе" />
		</Helmet>
		<div className="Home">
			<Header page="true" styles={styles.fadeInDown}/>
			<div className="Home__main">
				<Gradient />
				<div className="container-fluid h-100">
					<div className="row align-items-center align-content-center justify-content-center justify-content-lg-start h-100">
						<div className=" col-24 col-lg-15 col-xxl-14 col-xxxl-13 offset-xxl-1 offset-xxxl-2">
							<StyleRoot>
								<h1 style={styles.fadeInLeft}>Создаём потрясающие цифровые продукты</h1>
							</StyleRoot>
						</div>
						<div className="col-md-14 col-lg-9 col-xl-8 col-xxl-7 col-xxxl-6 offset-xl-1 offset-xxxl-1" >
							<Welcome styles={styles.fadeInRight}/>
						</div>
						<div className="col-24 d-none d-xxl-block position-absolute main__underline"><span>Веб и мобайл разработка</span></div>
					</div>
				</div>
			</div>
			<div className="Home__about-section">
				<div className="container-fluid pt-2 pb-5 pt-lg-4 pb-lg-7">
					<div className="row">
						<div className="col-24 d-none d-xxxl-block">
							<span className="Home__mini-title">О нас</span>
						</div>
					</div>
					<div className="row justify-content-center text-sm-center pt-8">
						<div className="col-lg-18 col-xl-16 col-xxl-14 col-xxxl-12">
						<StyleRoot>
							<h2 className="mb-4" className={visibleElementAboutSection ? "show" : ""}>Помогаем бизнесу с IT-проектами благодаря огромному опыту и сильной экспертизе</h2>
						</StyleRoot>
						</div>
					</div>
					<div className="row justify-content-center text-sm-center pb-5">
						<div className="col-lg-14 col-xl-12 col-xxl-10 col-xxxl-8">
							<StyleRoot>
								<p className={visibleElementAboutSection ? "show" : ""}>Создаём решения, которые ускорят развитие бизнеса. Наша цель - вместе с вами добиться серьёзных результатов.</p>
							</StyleRoot>
						</div>
					</div>
					<div className="row justify-content-center text-sm-center pb-3 pb-lg-6">
						<div className="col-24 col-lg-19 col-xl-17 col-xxl-15 col-xxxl-13">
							<div className="row">
								<div className="col-24 col-sm-8 mb-5 mb-sm-0 px-0">
									<StyleRoot>
										<div>
											<h2 className={visibleElementAboutSection ? "show" : ""}>100+</h2>
											<span className={visibleElementAboutSection ? "show" : ""}>успешных проектов</span>
										</div>
									</StyleRoot>
								</div>
								<div className="col-24 col-sm-8 mb-5 mb-sm-0 px-0" >
									<StyleRoot>
										<div>
											<h2 className={visibleElementAboutSection ? "show" : ""}>20+</h2>
											<span className={visibleElementAboutSection ? "show" : ""}>постоянных сотрудников</span>
										</div>
									</StyleRoot>
								</div>
								<div className="col-24 col-sm-8 mb-sm-0 px-0">
									<StyleRoot>
										<div>
											<h2 className={visibleElementAboutSection ? "show" : ""}>Лучшие</h2>
											<span className={visibleElementAboutSection ? "show" : ""}>инструменты</span>
										</div>
									</StyleRoot>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="Home__main-section">
				<div className="container-fluid pt-2 pb-5 pt-lg-4 pb-lg-7">
					<div className="row">
						<div className="col-24 d-none d-xxl-block">
							<span className="Home__mini-title">О ГЛАВНОМ</span>
						</div>
					</div>
					<div className="row pt-6 pt-sm-8">
						<div className="mb-5 mb-md-0 offset-xxl-1 offset-xxxl-2 col-24 col-md-14 col-lg-13 col-xl-13 col-xxl-12 col-xxxl-10 d-flex flex-wrap justify-content-between align-items-center">
							<div className={`main-section mb-5 mb-sm-0 ${visibleElementMainSection ? "show" : ""}`}>
									<h3 className="mb-2 mb-sm-4 pb-2">Разработка</h3>
									<span>Веб-сервисы</span>
									<span>Стартапы</span>
									<span>Корпоративные сайты</span>
									<span>Интернет-магазины</span>
									<span>Мобильные приложения</span>

							</div>
							<h3 className="d-none d-sm-block d-md-none d-lg-block">&</h3>
							<div className={`main-section-two ${visibleElementMainSection ? "show" : ""}`}>
									<h3 className="mb-2 mb-sm-4 pb-2">UI/UX дизайн</h3>
									<span>Аналитика</span>
									<span>Проектирование UI/UX</span>
									<span>UI/UX дизайн</span>
									<span>Юзабилити тестирование</span>
									<span>Фирменный стиль</span>
							</div>
						</div>
						<div className="offset-md-1 offset-lg-2 offset-xl-3 offset-xxxl-4 col-md-9 col-lg-9 col-xl-8 col-xxl-7 col-xxxl-6">
							<StyleRoot>
								<div className={`main-section__block-services p-5 p-md-4 d-flex flex-wrap align-content-between ${visibleElementMainSection ? "show" : ""}`}>
									
									<h3 className="mb-2 mb-md-4 pb-2 pr-2">Рассказать подробнее про услуги?</h3>
									<Link to="/services" className="main-btn w-100">Перейти в услуги</Link>
									
								</div>
							</StyleRoot>
						</div>
					</div>
				</div>
			</div>
			<div className="Home__portfolio-section" id="portfolio">
				<div className="container-fluid pt-2 pb-5 pt-lg-4 pb-lg-7">
					<div className="row">
						<div className="col-24 d-none d-xxl-block">
							<span className="Home__mini-title">лучшие кейсы</span>
						</div>
					</div>
					<div className="row pt-6 pt-lg-8">
						<div className="offset-xxl-1 offset-xxxl-2 col-xxl-22 col-xxxl-20 mb-6">
							<h2>Наше портфолио</h2>
						</div>
						<div className="offset-xxl-1 offset-xxxl-2 col-md-12 col-xxl-10 col-xxxl-9 porfolio-section__item mb-6">
							<Link to="/case/revomed">
								<div className="w-100 prod mb-4">
									<img className="d-block w-100" src={revomed} alt="revomed" />
								</div>
								<h3>Онлайн телемедицина с проверенными врачами</h3>
							</Link>
						</div>

						
						<div className="offset-xxl-1 offset-xxxl-2 col-md-12 col-xxl-10 col-xxxl-9 porfolio-section__item mb-6">
							<Link to="/case/easy2book">
								<div className="w-100 prod mb-4">
									<img className="d-block w-100" src={easy2book} alt="easy2book" />
								</div>
								<h3>Онлайн-запись и система управления бизнесом в сфере услуг</h3>
							</Link>
						</div>

						<div className="offset-xxl-2 col-md-12 col-xxl-10 col-xxxl-9 porfolio-section__item mb-6">
							<Link to="/contacts">
								<div className="w-100 prod mb-4">
									<img className="d-block w-100" src={yourProject} alt="yourProject" />
								</div>
								<h3>Перейти в Контакты</h3>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Belive animation={visibleElementBelive ? true : false}/>
			<div className="Home__blog-section">
				<div className="container-fluid pt-2 pb-5 pt-lg-4 pb-lg-7">
					<div className="row">
						<div className="col-24 d-none d-xxl-block">
							<span className="Home__mini-title">Наш блог</span>
						</div>
					</div>
					{posts ? 
						<News posts={posts}/>
					: ""
					}
				</div>
			</div>
			<Footer />
		</div>
		</>
	);
}

export default Home;

/*<div className="offset-xxl-2 col-md-12 col-xxl-10 col-xxxl-9 porfolio-section__item mb-6">
							<div className="w-100 develop mb-4">
								<img className="d-block w-100" src={jenis} alt="jenis" />
							</div>
							<h3>Платформа для игровых ставок Jenis Games</h3>
						</div>*/