import { useState, useEffect, useRef } from "react";
import API from "../utils/API";
import {Helmet} from "react-helmet";

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";
import PostCard from "../blocks/PostCard/PostCard";
import Loading from "../blocks/Loading/Loading";

// graphics
import array from './icons/array.svg';

// styles
import "./Blog.scss";

let Blog = () => {
	let [posts, setPosts] = useState(null);
	let [currentPosts, setCurrentPosts] = useState(null);

	let [pageCount, setPageCount] = useState(1);

	let [pageIndexMore, setPageIndexMore] = useState(pageCount - 1);
	let [currentPage, setCurrentPage] = useState(1);

	let loadNewPage = (e, newPageNumber) => {
		e.preventDefault();
		if(newPageNumber === currentPage) return false;
		setCurrentPage(newPageNumber);
		setCurrentPosts(posts.slice((newPageNumber - 1)*3, 3 +(newPageNumber - 1)*3 ));
		window.scrollTo(0, 0);
	}
	useEffect(() => {
		// GET BLOG
		let getBlog = async (data) => {
			let res = await API.get("/posts", data);
			return await res.data;
		}

		getBlog().then((result) => {
			let pages = Math.ceil(result.posts.length / 3);
			setPageCount(pages);
			setPageIndexMore(pageCount - 1);
			setCurrentPosts(result.posts.slice((currentPage - 1)*3, 3 +(currentPage - 1)*3 ));
			setPosts(result.posts);
		})
	}, [])

	return (
		<div className="Blog">
			<Header />
			<Helmet>
				<title>Блог Amont.studio</title>
			</Helmet>
			{ currentPosts ?
			<div className="Blog__main-section pt-8 pb-8">
				<div className="container-fluid mb-5">
					<div className="row align-items-center">
						<div className="col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 ">
							<div className="d-flex pb-3 mb-1">
								<span className="main-section__span">блог обо всём</span>
								<span className="main-section__span ml-3">новости студии</span>
							</div>
							<h1 className="pb-2">Свежие новости</h1>
						</div>
						
							{currentPosts.map((item, key) =>
								<div className="col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 mb-4 mb-xxxl-5" key={key}>
									<PostCard data={item}/>
								</div>
							)}
							
					</div>
					<div className="row blog-pagination mt-4">
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 d-flex">
						{
							(currentPage > 1) && (<button className="m-btn m-btn_rounded mr-2 mr-sm-3 " onClick={(e) => loadNewPage(e, currentPage-1)}><img src={array} alt="" className="back" /></button>)
						}
						{
							new Array(pageCount).fill().map((item, key, arr) => {
								let pageIndex = key+1;
								if ((((currentPage === pageIndex) || (currentPage-1 === pageIndex) || (currentPage === pageIndex-1)) && ((currentPage !== pageCount) || (currentPage !== 1))) || (((currentPage === pageIndex) || (currentPage === pageIndex-2) || (currentPage === pageIndex-1)) && (currentPage === 1)) || (((currentPage === pageIndex) || (currentPage-2 === pageIndex) || (currentPage-1 === pageIndex)) && (currentPage === pageCount))) {
									return (<button className={"m-btn m-btn_rounded mr-2 mr-sm-3 " + (pageIndex === currentPage ? "active" : "")} key={pageIndex} onClick={(e) => loadNewPage(e, pageIndex)}>{pageIndex}</button>);
								}
							})
						}
						{
							((currentPage < pageCount) && (pageCount !== 1)) && (<button className="m-btn m-btn_rounded mr-2 mr-sm-3 " onClick={(e) => loadNewPage(e, currentPage+1)}><img src={array} alt="" /></button>)
						}
						</div>
					</div>
				</div>
			</div>
			:
			<Loading />
			}	
			<Footer />
		</div>
	);
}

export default Blog;




/*{new Array(pageCount).fill().map((item, key, arr) => {
							let pageIndex = key+1;
							if ((arr.length >= 5) && (currentPage < 3)) {
								if((pageIndex < currentPage-1 && pageIndex < arr.length-2) || (pageIndex > currentPage+1 && pageIndex > 3) || (pageIndex === arr.length)) return ("");
								else 
							} else if (((arr.length >= 5) && (currentPage > 3) && (pageIndex === 1)) || ((arr.length >= 5) && (currentPage === 3) && ((pageIndex === 2) || (pageIndex === 1)))) {
								return (<button className={"m-btn m-btn_rounded mr-2 mr-sm-3 " + (pageIndex === currentPage ? "active" : "")} key={pageIndex} onClick={(e) => loadNewPage(e, pageIndex)}>{pageIndex} 33</button>);
							} else if (arr.length < 5)  return (<button className={"m-btn m-btn_rounded mr-2 mr-sm-3 " + (pageIndex === currentPage ? "active" : "")} key={pageIndex} onClick={(e) => loadNewPage(e, pageIndex)}>{pageIndex} 44</button>);
						})}
						
						{
							((currentPage === pageCount - 3) && (pageCount > 5) && (currentPage !== 3)) && (<button className={"m-btn m-btn_rounded mr-2 mr-sm-3 " + (pageIndexMore === currentPage ? "active" : "")} onClick={(e) => loadNewPage(e, pageIndexMore)}>{pageIndexMore} 55</button>)
						}
						{
							new Array(pageCount).fill().map((item, key, arr) => {
							let pageIndex = key+1;
							if ((pageIndex === arr.length) && (arr.length > 4) || ((arr.length > 4) && (pageIndex > arr.length - 3) && (currentPage >= 3))) return ();
						})}*/