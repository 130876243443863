import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

// styles
import "./PostCard.scss";

let PostCard = (props) => {
	return (
		<Link to={"/post/"+props.data.id} className="PostCard d-flex flex-wrap justify-content-between p-4 p-sm-5">
			<div className="PostCard__main-info d-flex flex-wrap mb-4 mb-sm-0">
				<span className="main-info__category mb-5">{props.data.category}</span>
				<h3 className="mb-3">{props.data.title}</h3>
				<span className="main-info__date">{DateTime.fromISO(props.data.date).setLocale('ru').toFormat('dd MMMM yyyy')}</span>
			</div>
			<div className="PostCard__author d-flex align-items-end justify-content-end">
				<div style={{backgroundImage: "url("+props.data.author.photo+")", backgroundPosition: "center", backgroundSize: "cover"}} className="photo mr-3"></div>
				<div className="author__info">
					<span className="info__name d-block">{props.data.author.name}</span>
					<span className="info__job d-block">{props.data.author.job}</span>
				</div>
			</div>
		</Link>
	);
}

export default PostCard;