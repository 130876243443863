import { Link } from "react-router-dom";

import Slider from "../Slider/Slider";

// styles
import "./News.scss";

let News = (props) => {
	return (
		<div className="row pt-8 align-items-center News">
			<div className="offset-xxl-1 offset-xxxl-2 col-24 col-xl-3 col-xxl-3 col-xxxl-2">
				<h2 className="mb-3 mb-xl-0">Свежие новости</h2>
			</div>
			<div className="offset-xxxl-1 col-xl-21 col-24 col-xxl-19 col-xxxl-17">
				<Slider info={props.posts}/>
			</div>
		</div>
	);
}

export default News;