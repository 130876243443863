import React, { useState, useEffect, useContext, createContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ScrolltoTop from "./utils/ScrolltoTop";

import "./App.scss";

// components
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import CaseRevomed from "./pages/CaseRevomed";
import CaseEasy2Book from "./pages/CaseEasy2Book";
import Post from "./pages/Post";
import Contacts from "./pages/Contacts";
import Services from "./pages/Services";


// GET USERS
let App = () => {
  return (
	<div className="App">
		<Router>
	  		<Route exact path="/" component={Home} />
	  		<Route exact path="/blog" component={Blog} />
	  		<Route exact path="/case/revomed" component={CaseRevomed} />
	  		<Route exact path="/case/easy2book" component={CaseEasy2Book} />
	  		<Route exact path="/post/:idPost" component={Post} />
	  		<Route exact path="/contacts" component={Contacts} />
	  		<Route exact path="/services" component={Services} />
	  		<ScrolltoTop />
	  	</Router>
	</div>
  );
}

export default App;
