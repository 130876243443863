// styles
import "./Gradient.scss";

let Gradient = () => {
	return (
		<div className="Gradient">
			<div className="main__blue"></div>
			<div className="main__red"></div>
			<div className="main__blue-two"></div>
			<div className="main__purple"></div>
			<div className="main__purple-two"></div>
			<div className="main__pink"></div>
			<div className="main__purple-three"></div>
			<div className="main__pink-two"></div>
		</div>
	);
}

export default Gradient;