import { useState, useEffect, useRef } from "react";
import API from "../utils/API";
import {Helmet} from "react-helmet";

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";
import Belive from "../blocks/Belive/Belive";

// graphics
import Telegram from './icons/Telegram.svg';
import WhatsApp from './icons/WhatsApp.svg';
import clip from './icons/clip.svg';

// styles
import "./Contacts.scss";

let Contacts = () => {
	let applicationForm = useRef(null);
	let [submitForm, setSubmitForm] = useState(false)

	let postApplication = async (data) => {
		let res = await API.post("/applications", data);
		return await res.data;
	}

	let sendApplication = (e) => {
		e.preventDefault();
		setSubmitForm(true);
		console.log("applicationForm.current", applicationForm.current)
		let postData = new FormData(applicationForm.current);
		console.log("postData", postData)
		postApplication(postData).then((result) => {
			if (result.success) {
					setSubmitForm(false);
					alert('успешно');
				} else {
					setSubmitForm(false);
					alert('ошибка');
				}
		})
	}

	let [uploadedPhotoProfileName, setUploadedPhotoProfileName] = useState(null);
	let readURL = (input) => {
		console.log("input", input.target)
		if (input.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				console.log("input.target.files[0].name", input.target.files[0].name)
				setUploadedPhotoProfileName(input.target.files[0].name);
			}
			reader.readAsDataURL(input.target.files[0]);
		}
	}
	return (
		<>
		<div className="Contacts">
			<Header />
			<Helmet>
				<title>Контакты — Amont.studio</title>
				<meta name="description" content="Веб-студия Amont. Свяжитесь с нами, чтобы обсудить проект" />
			</Helmet>
			<div className="Contacts__main-section pt-8 pb-8">
				<div className="container-fluid">
					<div className="row">
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 pb-7">
							<h1>Контакты</h1>
						</div>
						<div className="col-24 col-lg-10 col-xl-10 offset-xxl-1 offset-xxxl-2 mb-6 mb-lg-0 d-flex flex-wrap align-content-between">
							<div className="mb-5 mb-lg-0">
								<div className="mb-4 pb-1">
									<span className="pb-2 d-block">Наш email</span>
									<a href="mailto:hello@amont.studio"><h2>hello@amont.studio</h2></a>
								</div>
								<div className="main-section__messengers">
									<span className="pb-3 d-block messengers__span">Мессенджеры</span>
									<div className="mb-2">
										<a href="https://t.me/amont_studio" className="d-flex align-items-center"><img src={Telegram} alt="Telegram" className="mr-2"/>Telegram</a>
									</div>
									<div>
										<a href="https://wa.me/79175654200" className="d-flex align-items-center"><img src={WhatsApp} alt="WhatsApp" className="mr-2"/>WhatsApp</a>
									</div>
								</div>
							</div>
							<div className="special__adresses d-block w-100">
								<span className="d-block mb-1">Специальные адреса</span>
								<a href="mailto:hr@amont.studio" className="d-block">hr@amont.studio — для резюме</a>
								<a href="mailto:parnter@amont.studio" className="d-block">parnter@amont.studio — сотрудничество</a>
							</div>
						</div>
						<div className="col-24 col-lg-14 col-xl-14 col-xxl-12 col-xxxl-10">
							<form onSubmit={sendApplication} ref={applicationForm} className="d-flex flex-wrap justify-content-center py-5 px-4 py-sm-7 px-sm-6">
								<h2 className="mb-3">Начать проект</h2>
								<div className="form__holder d-flex justify-content-between flex-wrap align-items-center">
									<input type="text" name="name" placeholder="Имя *" className="mb-3" required/>
									<input type="text" name="phone" placeholder="Телефон *" className="mb-3" required/>
									<input type="text" name="email" placeholder="Email *" className="mb-3" required/>
									<input type="text" name="company" placeholder="Компания" className="mb-3"/>
									<textarea placeholder="Описание" name="description" className="mb-3"></textarea>
									<label className="file__holder mb-3 d-flex justify-content-between align-items-center">
										{
											uploadedPhotoProfileName ? <span>{uploadedPhotoProfileName}</span> : <span>Прикрепить ТЗ или бриф</span>
										}
										<input type="file" name="file" className="d-none" onChange={(e) => readURL(e)}/>
										<img src={clip} alt="clip" />
									</label>
									<div className="submit__holder">
										<button>
										{ !submitForm ? "Отправить запрос" : <div className="Loading-holder"></div>}
										</button>
									</div>
									<p className="mb-0">Нажимая на “Отправить запрос”, я даю своё согласие на обработку моих персональных данных</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Belive page="contacts" animation={true}/>
			<Footer />
		</div>
		</>
	);
}

export default Contacts;