import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Radium, {StyleRoot} from 'radium';


// graphics
//import Menu from './icons/Menu.svg';
//import Logo from './icons/Logo.svg';

import Cross from './icons/Cross.svg';
import Logo from './icons/Logo.js';
import Menu from './icons/Menu.js';
// styles
import "./Header.scss";

Modal.setAppElement('#root');

let Header = (props) => {
	
	const [modalAddFilialIsOpen, setModalAddFilialIsOpen] = useState(false);
	
	function openModalAddFilial() {
		setModalAddFilialIsOpen(true);
		setScroll(false);
	}
	function closeModalAddFilial() {
		setModalAddFilialIsOpen(false);
		(props.page) ? (window.pageYOffset > document.documentElement.clientHeight) ? setScroll(true) : setScroll(false) : (window.pageYOffset > 0) ? setScroll(true) : setScroll(false);
	}

	const [scroll, setScroll] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', function() {
			(props.page) ? (window.pageYOffset > document.documentElement.clientHeight) ? setScroll(true) : setScroll(false) : (window.pageYOffset > 0) ? setScroll(true) : setScroll(false);
		});
		(props.page) ? (window.pageYOffset > document.documentElement.clientHeight) ? setScroll(true) : setScroll(false) : (window.pageYOffset > 0) ? setScroll(true) : setScroll(false);
	}, []);

	

	return (
		<>
		<StyleRoot>
			<div className={`Header ${scroll ? "scroll" : ""}`} >
				<div className="container-fluid py-2" style={props.styles ? props.styles : {}}>
					<div className="row">
						<div className="col-24 Header__holder">
							<Link to="/" onClick={() => {closeModalAddFilial(); window.scrollTo(0, 0);}}><Logo /></Link>
							{modalAddFilialIsOpen 
							?
							<button onClick={() => closeModalAddFilial()}><img src={Cross} alt=""/></button>
							:
							<button onClick={() => openModalAddFilial()}><Menu /></button>}
							<div className="d-flex justify-content-end align-items-center">
								<a className="holder__link" href="mailto:hello@amont.studio">hello@amont.studio</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyleRoot>

		<Modal
        isOpen={modalAddFilialIsOpen}
        onRequestClose={closeModalAddFilial}
        className="Modal"
        overlayClassName="OverlayModal"
      >
        <div className="modal_inner pt-9">
        	<div className="container-fluid">
        		<div className="row">
        			<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 d-flex flex-wrap justify-content-between">
        				<div>
	        				<Link to="/" onClick={() => {closeModalAddFilial(); window.scrollTo(0, 0);} }><h2 className="mb-2 pb-1">Главная</h2></Link>
	        				<Link to="/services" onClick={() => {closeModalAddFilial(); window.scrollTo(0, 0);}}><h2 className="mb-2 pb-1">Услуги</h2></Link>
	        				<Link to="/blog" onClick={() => {closeModalAddFilial(); window.scrollTo(0, 0);}}><h2 className="mb-2 pb-1">Блог</h2></Link>
	        				<Link to="/contacts" onClick={() => {closeModalAddFilial(); window.scrollTo(0, 0);}}><h2 className="mb-2 pb-1">Контакты</h2></Link>
	        			</div>
	        			<div className="d-none d-sm-block">
							<div className="mb-4 pb-1">
								<span className="pb-2 d-block">Наш email</span>
								<a href="mailto:hello@amont.studio"><h2>hello@amont.studio</h2></a>
							</div>
						</div>
        			</div>
        		</div>
        		<span className="mini__span pb-4">© Amont.studio, 2015 — 2021</span>
        	</div>
        </div>
      </Modal>
      </>
	);
}

export default Header;