import { Link } from "react-router-dom";
import Radium, {StyleRoot} from 'radium';

// styles
import "./Welcome.scss";

let Welcome = (props) => {
	return (
		<StyleRoot>
		<div className="Welcome p-5 p-md-4" style={props.styles ? props.styles : {}}>
			<span className="mb-5 mb-md-7 pb-4 d-block">Добро пожаловать в Amont.studio</span>
			<h3 className="mb-2 mb-md-4 pb-3">Давайте делать классные проекты вместе?</h3>
			<Link to="/contacts" className="main-btn">Перейти в контакты</Link>
		</div>
		</StyleRoot>
	);
}

export default Welcome;