import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";

// graphics
import link from './icons/link.svg';
import easy2book from './icons/easy2book.svg';

import easy2bookCase1 from './img/easy2bookCase1.png';
import easy2bookCase2 from './img/easy2bookCase2.png';
import easy2bookCase3 from './img/easy2bookCase3.png';
import easy2bookCase4 from './img/easy2bookCase4.png';
import easy2bookCase5 from './img/easy2bookCase5.png';

// styles
import "./Case.scss";

let CaseRevomed = () => {
	return (
		<div className="Case">
			<Header />
			<Helmet>
				<title>Кейс Easy2Book — Amont.studio</title>
				<meta name="description" content="Рассказываем о разработке сервиса онлайн-записи и автоматизации бизнес процессов Easy2Book" />
			</Helmet>
			<div className="Case__main-section pt-8 pb-8">
				<div className="container-fluid mb-8">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-11 offset-xxl-1 offset-xxxl-2 mb-8 d-flex flex-wrap">
							<span className="d-block mb-4">Кейсы / Easy2Book</span>
							<h1 className="mb-4">Сервис онлайн-записи и автоматизации ваших бизнес процессов</h1>
							<a href="https://easy2book.ru" className="d-flex align-items-center justify-content-center"><img src={link} alt="" className="mr-2"/>Перейти на сайт</a>
						</div>
						<div className="col-4 col-xl-3 offset-3 offset-xl-6 mb-8 d-none d-lg-block">
							<div className="Case__logo easy2book d-flex justify-content-center align-items-center">
								<img src={easy2book} alt="" />
							</div>
						</div>
						<div className="col-24 col-lg-15 col-xl-13 col-xxl-11 col-xxxl-10 offset-xxl-1 offset-xxxl-2">
							<span className="d-block mb-4">Задача</span>
							<p className="mb-4">Создать уникальную платформу, которая поможет бизнесу любого масштаба из сферы услуг легко и просто контролировать работу сотрудников и предоставлять своим клиентам удобство бронирования услуг.</p>
							<p>Необходимо придумать фирменный стиль, создать формы бронирования, посадочные страницы и спроектировать административную часть</p>
						</div>
					</div>
				</div>
				<div className="container-fluid p-0 mb-7 pb-5">
					<img src={easy2bookCase1} className="w-100"/>
				</div>
				<div className="container-fluid mb-8">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2 mb-8">
							<h2 className="mb-4">Overview и технологии</h2>
							<p className="mb-4">Для проекта был создан фирменный стиль. Используемые шрифты —  Gilroy и Lato, цвета — белый, светло-серый и синий, ассоциирующиеся с легкостью, удобством, простотой и безопасностью. Всего платформа включает более 100 макетов-страниц. Проект разрабатывался с 2019 года и финально был выпущен в 2021.</p>
							<p>Фронтенд проекта состоит из нескольких React-приложений, бекенд написан на NodeJS.</p>
						</div>
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2">
							<h2 className="mb-4">Бронирование для клиентов</h2>
							<p>Быстрый и понятный формат. Начать бронирование можно с любой составляющей: даты, специалиста или услуги. После этого необходимо заполнить имя и телефон, после чего сразу придёт смс-оповещение </p>
						</div>
					</div>
				</div>
				<div className="container-fluid p-0 mb-7 pb-5">
					<img src={easy2bookCase2} className="w-100"/>
				</div>
				<div className="container-fluid mb-7">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2 mb-5">
							<h2 className="mb-4">Главная страница</h2>
						</div>
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 mb-5">
							<img src={easy2bookCase3} className="w-100"/>
						</div>
					</div>
				</div>
				<div className="container-fluid mb-8">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2">
							<h2 className="mb-4">Административная панель</h2>
							<p>Здесь всё, чтобы с легкостью управлять бизнесом в сфере услуг. Редактируйте и актуализируйте расписание сотрудников, добавляйте новые услуги, следите за статистикой по десяткам метрик </p>
						</div>
					</div>
				</div>
				<div className="container-fluid p-0 mb-7 pb-5">
					<img src={easy2bookCase4} className="w-100"/>
				</div>
				<div className="container-fluid mb-7">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2 mb-5">
							<h2 className="mb-4">Простая регистрация</h2>
							<p>Личный кабинет настраивается для каждого клиента платформы индивидуально, чтобы обечпечить ему максимальное удобство пользования. В начале пути достаточно заполнить простую заявку, а дальше всё сделает менеджер по сопровождению клиентов</p>
						</div>
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 mb-5">
							<img src={easy2bookCase5} className="w-100"/>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-24 col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 d-flex flex-wrap">
							<h2 className="w-100 mb-4">Компетенции</h2>
							<span className="main-section__span mr-3 mb-3">ИССЛЕДОВАНИЕ</span>
							<span className="main-section__span mr-3 mb-3">АНАЛИТИКА</span>
							<span className="main-section__span mr-3 mb-3">проектирование</span>
							<span className="main-section__span mr-3 mb-3">ui</span>
							<span className="main-section__span mr-3 mb-3">UX</span>
							<span className="main-section__span mr-3 mb-3">ЛОГОТИП</span>
							<span className="main-section__span mr-3 mb-3">ВЕБ-дизайн</span>
							<span className="main-section__span mr-3 mb-3">Front-end</span>
							<span className="main-section__span mr-3 mb-3">back-end</span>
							<span className="main-section__span mr-3 mb-3">ТЕСТИРОВАНИЕ</span>
							<span className="main-section__span mr-3 mb-3">NODEjs</span>
							<span className="main-section__span mr-3 mb-3">REACT</span>
							<span className="main-section__span mr-3 mb-3">ФИРМЕННЫЙ СТИЛЬ</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CaseRevomed;