import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

//components
import Gradient from "../../atoms/Gradient/Gradient";

// graphics
import Logo from './icons/Logo.js';

// styles
import "./Footer.scss";

let Footer = () => {
	return (
		<div className="Footer">
			<div className="container-fluid pt-4">
				<div className="row pt-7 pb-6">
					<div className=" order-3 order-lg-0 mt-6 mt-lg-0 col-24 col-lg-5 col-xl-4 col-xxxl-3 offset-xxl-1 offset-xxxl-2 d-flex flex-wrap align-content-between">
						<div className="w-100">
							<Logo />
							<p className="d-block pt-3">Создаём потрясающие цифровые продукты</p>
						</div>
						<span>© Amont.studio, 2015 — 2021 </span>
					</div>
					<div className="mb-5 mb-md-0  col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-xxxl-2 offset-lg-1 offset-xl-2 offset-xxl-2 offset-xxxl-3">
						<p className="pb-3">Информация</p>
						<div className="pb-3">
							<Link to="/services">Услуги</Link>
						</div>
						<div className="pb-3">
							<Link to="/blog">Блог</Link>
						</div>
						<div className="pb-3">
							<HashLink to="/#portfolio">Портфолио</HashLink>
						</div>
					</div>
					<div className="mb-5 mb-md-0  col-md-4 col-lg-3 col-xl-3 col-xxl-3 col-xxxl-2 offset-md-2 offset-lg-1 offset-xl-2 offset-xxl-1 offset-xxxl-2">
						<p className="pb-3">Соц. сети</p>
						<div className="pb-3">
							<a href="https://be.net/amont_studio" target="_blank">Behance</a>
						</div>
						<div className="pb-3">
							<a href="https://www.facebook.com/amont.studio" target="_blank">Facebook</a>
						</div>
						<div className="pb-3">
							<a href="https://instagram.com/amont.studio" target="_blank">Instagram</a>
						</div>
					</div>
					<div className="col-md-11 col-lg-9 col-xl-7 col-xxl-7 col-xxxl-6 offset-md-3 offset-lg-2 offset-xl-3 offset-xxl-2 position-relative">
						<div className="holder p-5 p-md-4">
							<Gradient />
							<div className="position-relative p-2">
								<h2 className="mb-4 pb-2">Свяжитесь с нами, чтобы обсудить проект</h2>
								<Link to="/contacts" className="second-btn">Перейти в контакты</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;