import "./Loading.scss";

let Loading = (props) => {
	return (
		<div className="Loading">
			<div className="Loading-holder"></div>
		</div>
	);
}

export default Loading;