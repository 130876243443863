import { useState, useEffect, useRef } from "react";
import {Helmet} from "react-helmet";

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";
import Welcome from "../blocks/Welcome/Welcome";

// graphics


// styles
import "./Services.scss";

let Services = () => {
	return (
		<div className="Services">
			<Header />
			<Helmet>
				<title>Наши услуги — Amont.studio</title>
				<meta name="description" content="Мы разрабатываем сложные веб-сервисы, корпоративные и промосайты, e-commerce решения, личные кабинеты и порталы. Сосредоточены на потребностях бизнеса и его клиентов. Создаём уникальные решения, построенные на современных технологиях" />
			</Helmet>
			<div className="Services__main-section pt-9 pb-8">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-24 col-lg-17 col-xl-15 col-xxl-13 col-xxxl-12 offset-xxl-1 offset-xxxl-2">
							<h1 className="pb-4">Услуги</h1>
							<h2 className="pb-4 mb-2">Мы разрабатываем сложные веб-сервисы, корпоративные и промосайты, e-commerce решения, личные кабинеты и порталы. Сосредоточены на потребностях бизнеса и его клиентов. Создаём уникальные решения, построенные на современных технологиях</h2>
						</div>
						<div className="col-24 col-lg-15 col-xl-13 col-xxxl-10 offset-xxl-1 offset-xxxl-2 mb-8">
							<p>Подход</p>
							<p>Начинаем работу со знакомства с бизнесом. Умеем погружаться в специфику рынка, проводить анализ конкурентов и целевой аудитории. Выявляем сложности взаимодействия клиента с продуктом онлайн. После этого проектируем лучшее решение</p>
						</div>	
						<div className="col-lg-12 mb-6 mb-lg-0 col-xl-12 offset-xxl-1 offset-xxxl-2">
							<h2 className="mb-3">Ключевые компетенции</h2>
							<div className="py-4 main-section__item">
								<h3 className="my-2">1. Анализ отрасли</h3>
							</div>
							<div className="py-4 main-section__item">
								<h3 className="my-2">2. Дизайн и проектирование</h3>
							</div>
							<div className="py-4 main-section__item">
								<h3 className="my-2">3. Front-end разработка</h3>
							</div>
							<div className="py-4 main-section__item">
								<h3 className="my-2">4. Back-end разработка</h3>
							</div>
							<div className="py-4 main-section__item">
								<h3 className="my-2">5. Техническая поддержка</h3>
							</div>
						</div>
						<div className="col-24 col-lg-10 col-xl-9 col-xxl-7 col-xxxl-6 offset-lg-2 offset-xl-3 offset-xxl-3 offset-xxxl-1">
							<Welcome />
						</div>				
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Services;