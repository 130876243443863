import { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import {Helmet} from "react-helmet";

import API from "../utils/API";
import EditorJs from "react-editor-js";

import EditorJsParagraph from '@editorjs/paragraph'
import EditorJsList from '@editorjs/list'
import EditorJsLinkTool from '@editorjs/link'
import EditorJsHeader from '@editorjs/header'

//components
import Header from "../blocks/Header/Header";
import Footer from "../blocks/Footer/Footer";
import News from "../blocks/News/News";
import Loading from "../blocks/Loading/Loading";

// graphics
import copyLinkPost from './icons/copyLinkPost.svg';
import facebookPost from './icons/facebookPost.svg';
import twitterPost from './icons/twitterPost.svg';
import vkPost from './icons/vkPost.svg';


// styles
import "./Post.scss";

let Post = (props) => {
	let [post, setPost] = useState(null)
	let [posts, setPosts] = useState(null);

	let [copied, seCopied] = useState(false);
	let copyLink = () => {
		navigator.clipboard.writeText(window.location.href);
		console.log("window.location.href", window.location.href);
		seCopied(true);
		setTimeout(()=>{
			seCopied(false);
		}, 5000)
	}

	// POST CONTENT EDITOR 
	const EDITOR_JS_TOOLS = {
		paragraph: EditorJsParagraph,
		list: EditorJsList,
		linkTool: EditorJsLinkTool,
		header: EditorJsHeader,
	}
	// / POST CONTENT EDITOR 


	
	useEffect(() => {
		// GET BLOG
		let getBlog = async (data) => {
			let res = await API.get("/posts", data);
			return await res.data;
		}
		getBlog().then((result) => {
			setPosts(result.posts);
		})
	}, [])

	useEffect(() => {
	    setPost(null);
		let getPost = async (id) => {
			let res = await API.get("/posts/" + id)
			return await res.data;
		}
		getPost(props.match.params.idPost).then((result) => {
			setPost(result.post);
		})

	}, [props.match.params.idPost]);

	




	return (
		<>
		<div className="Post">
			<Header />
			{post ? <>
				<Helmet>
					<title>{post.title} — Блог Amont.studio</title>
				</Helmet>
			<div className="Post__main-section" style={{backgroundImage: "url("+post.photo+")", backgroundPosition: "center", backgroundSize: "cover"}}></div>
			<div className="Post__post-section pt-9">
				<div className="container-fluid pt-8">
					<div className="row align-items-center ">
						<div className="col-xxl-15 col-xxxl-13 offset-xxl-1 offset-xxxl-2 post__holder d-flex flex-wrap pb-6">
							<span className="main-section__category mb-3">{post.category}</span>
							<h1 className="pb-1">{post.title}</h1>
							<span className="main-info__date">{DateTime.fromISO(post.date).setLocale('ru').toFormat('dd MMMM yyyy')}</span>
						</div>
						<div className="col-xxl-22 col-xxxl-20 offset-xxl-1 offset-xxxl-2 holder">
							<div className="p-3 p-md-5 p-lg-7 px-lg-8 text__holder">
								<div className="mx-4 my-3">
									<div>
										<EditorJs minHeight={0} tools={EDITOR_JS_TOOLS} data={post.description} readOnly />
									</div>
									<div className="d-flex flex-wrap align-items-center justify-content-between mt-7">
										<div className="d-flex align-items-center">
											<div style={{backgroundImage: "url("+post.author.photo+")", backgroundPosition: "center", backgroundSize: "cover"}} className="photo mr-3"></div>
											<div className="author__info">
												<span className="info__name d-block mb-1">{post.author.name}</span>
												<span className="info__job d-block">{post.author.job}</span>
											</div>
										</div>
										<div className="share mt-4 mt-sm-0">
											<span className="pb-3 d-block info__share">Поделиться статьёй</span>
											<div>
												<span className={`copied  ${copied ? "d-flex" : "d-none"}`}>Ссылка скопирована</span>
												<a href={"https://vk.com/share.php?url="+window.location.href+"&image="+post.photo+"&title="+post.title} target="_blank"><img src={vkPost} alt="" className="mr-2"/></a>
												<a href={"http://www.facebook.com/sharer/sharer.php?s=100&p%5Btitle%5D="+ post.title +"&p%5Bsummary%5D="+ post.title +"&p%5Burl%5D="+window.location.href+"&p%5Bimages%5D%5B0%5D="+post.photo} target="_blank"><img src={facebookPost} alt="" className="mr-2"/></a>
												<a href={"https://twitter.com/intent/tweet?original_referer=http%3A%2F%2Ffiddle.jshell.net%2F_display%2F&text="+post.title+"&url="+window.location.href} target="_blank"><img src={twitterPost} alt="" className="mr-2"/></a>
												<img className="copy" src={copyLinkPost} alt="" onClick={()=> copyLink()}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>
				{posts 
					?
					<div className="Post__blog-section">
						<div className="container-fluid pt-4 pb-7">
							<News posts={posts}/>
						</div>
					</div>
					:
					"загрузка"
				}
				
			</div>
			</> : <Loading />}
			<Footer />
		</div>
		</>
	);
}

export default Post;