import { Link } from "react-router-dom";
import Radium, {StyleRoot} from 'radium';

// styles
import "./Belive.scss";

let Belive = (props) => {
	return (
		<div className="Belive">
			<div className="container-fluid py-7">
				<div className="row justify-content-sm-center py-5">
				
					<div className="col-lg-16 col-xl-14 col-xxl-12 col-xxxl-10 text-sm-center">
					<StyleRoot>
						<div>
							<h2 className={`mb-3 ${props.animation ? "show" : ""}`}>Верим, что качественный продукт может получиться только после исследования и полного погружения</h2>
							{
								props.page !== "contacts" 
								&&
								<Link to="/contacts" className={props.animation ? "show" : ""}>Хочу обсудить проект</Link>
							}
						</div>
						</StyleRoot>
					</div>
				
				</div>
			</div>
		</div>
	);
}

export default Belive;